import { gql } from '@apollo/client';
import { PARTNER_FIELDS } from '../fragments/Partner';

export const CURRENT_USER_PERMISSIONS = gql`
  ${PARTNER_FIELDS}
  query CurrentUserPermissionsQuery {
    currentUserPermissions {
      partner {
        ...PartnerFields
      }
      roles
    }
  }
`;
