function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_SEARCH = '/dashboard/search';
const ROOTS_MANAGEMENT = '/dashboard/management';
const ROOTS_ADMIN = '/dashboard/admin';

export const PATH_AUTH = {
  root: ROOTS_AUTH,

  login: path(ROOTS_AUTH, 'login'),
  resetPassword: path(ROOTS_AUTH, 'reset-password'),
  forgotPassword: path(ROOTS_AUTH, 'forgot-password'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,

  home: path(ROOTS_DASHBOARD, '/home'),

  search: {
    games: path(ROOTS_SEARCH, '/games'),
    reports: path(ROOTS_SEARCH, '/reports'),
  },

  management: {
    games: path(ROOTS_MANAGEMENT, '/games'),
    gamesCreate: path(ROOTS_MANAGEMENT, '/games/new'),
    gamesEdit: (id: number) => path(ROOTS_MANAGEMENT, `/games/${id}/edit`),

    users: path(ROOTS_MANAGEMENT, '/users'),
    usersCreate: path(ROOTS_MANAGEMENT, '/users/new'),
    usersEdit: (id: string) => path(ROOTS_MANAGEMENT, `/users/${id}/edit`),
  },

  admin: {
    servers: path(ROOTS_ADMIN, '/servers'),
    serversCreate: path(ROOTS_ADMIN, '/servers/new'),
    serversEdit: (id: number) => path(ROOTS_ADMIN, `/servers/${id}/edit`),

    partners: path(ROOTS_ADMIN, '/partners'),
    partnersCreate: path(ROOTS_ADMIN, '/partners/new'),
    partnersEdit: (id: string) => path(ROOTS_ADMIN, `/partners/${id}/edit`),

    games: path(ROOTS_ADMIN, '/games'),
    gamesCreate: path(ROOTS_ADMIN, '/games/new'),
    gamesEdit: (id: number) => path(ROOTS_ADMIN, `/games/${id}/edit`),

    providers: path(ROOTS_ADMIN, '/providers'),
    providersEdit: (id: number) => path(ROOTS_ADMIN, `/providers/${id}/edit`),

    studios: path(ROOTS_ADMIN, '/studios'),
    studiosCreate: path(ROOTS_ADMIN, '/studios/new'),
    studiosEdit: (id: number) => path(ROOTS_ADMIN, `/studios/${id}/edit`),
  },
};
