import createAvatar from '../utils/createAvatar';
import Avatar, { Props as AvatarProps } from './Avatar';

export default function MyAvatar({ src, alt, ...other }: AvatarProps) {
  return (
    <Avatar src={src} alt={alt} color={src ? 'default' : createAvatar(alt || '').color} {...other}>
      {createAvatar(alt || '').name}
    </Avatar>
  );
}
