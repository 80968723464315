import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import LoadingScreen from '../components/LoadingScreen';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import DashboardLayout from '../layouts/dashboard';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen isDashboard={true} />}>
      <Component {...props} />
    </Suspense>
  );

export default function Router() {
  return useRoutes([
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),

      children: [
        { element: <Navigate to="/dashboard/home" replace />, index: true },

        {
          path: 'home',
          children: [{ element: <DashboardHome />, index: true }],
        },

        // SEARCH & REPORTS
        {
          path: 'search',
          children: [
            { path: 'games', element: <SearchGame /> },
            { path: 'reports', element: <Reports /> },
          ],
        },

        // MANAGEMENT
        {
          path: 'management',
          children: [
              {
              path: 'users',
              children: [
                { element: <UserList />, index: true },
                { path: 'new', element: <UserCreate /> },
                { path: ':id/edit', element: <UserCreate /> },
              ],
            },
          ],
        },

        // ADMIN
        {
          path: 'admin',
          children: [
            {
              path: 'servers',
              children: [
                { element: <ServerList />, index: true },
                { path: 'new', element: <ServerCreate /> },
                { path: ':id/edit', element: <ServerCreate /> },
              ],
            },
            {
              path: 'partners',
              children: [
                { element: <PartnerList />, index: true },
                { path: 'new', element: <PartnerCreate /> },
                { path: ':id/edit', element: <PartnerCreate /> },
              ],
            },
            {
              path: 'games',
              children: [
                { element: <AdminGameList />, index: true },
                { path: 'new', element: <AdminGameCreate /> },
                { path: ':id/edit', element: <AdminGameCreate /> },
              ],
            },
            {
              path: 'providers',
              children: [
                { element: <ProviderList />, index: true },
                { path: ':id/edit', element: <ProviderCreate /> },
              ],
            },
            {
              path: 'studios',
              children: [
                { element: <StudiosList />, index: true },
                { path: 'new', element: <StudiosCreate /> },
                { path: ':id/edit', element: <StudiosCreate /> },
              ],
            },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '/',
      element: <GuestGuard />,

      children: [
        { element: <Navigate to="/login" replace />, index: true },
        { path: 'login', element: <Login /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'reset-password', element: <ResetPassword /> },
      ],
    },

    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/login/Login')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/reset-password/ResetPassword')));
const ForgotPassword = Loadable(lazy(() => import('../pages/auth/forgot-password/ForgotPassword')));

// DASHBOARD
const DashboardHome = Loadable(lazy(() => import('../pages/dashboard/DashboardHome')));

// SEARCH & REPORTS
const SearchGame = Loadable(lazy(() => import('../pages/reports/search-game/SearchGame')));
const Reports = Loadable(lazy(() => import('../pages/reports/reports/Reports')));

// MANAGEMENT
const UserList = Loadable(lazy(() => import('../pages/management/users/UserList')));
const UserCreate = Loadable(lazy(() => import('../pages/management/users/UserCreate')));

// ADMIN
const ServerList = Loadable(lazy(() => import('../pages/admin/servers/ServerList')));
const ServerCreate = Loadable(lazy(() => import('../pages/admin/servers/ServerCreate')));
const PartnerList = Loadable(lazy(() => import('../pages/admin/partners/PartnerList')));
const PartnerCreate = Loadable(lazy(() => import('../pages/admin/partners/PartnerCreate')));
const AdminGameList = Loadable(lazy(() => import('../pages/admin/games/GameList')));
const AdminGameCreate = Loadable(lazy(() => import('../pages/admin/games/GameCreate')));
const ProviderList = Loadable(lazy(() => import('../pages/admin/providers/ProviderList')));
const ProviderCreate = Loadable(lazy(() => import('../pages/admin/providers/ProviderCreate')));
const StudiosList = Loadable(lazy(() => import('../pages/admin/studios/Studios')));
const StudiosCreate = Loadable(lazy(() => import('../pages/admin/studios/StudiosCreate')));

const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
