import {ApolloClient, ApolloProvider, HttpLink, InMemoryCache} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';

import Router from './routes';
import ThemeProvider from './theme';

import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';

const link = new HttpLink({
  uri: process.env.REACT_APP_API_URL,
   credentials: 'include',
});

export const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
  credentials: 'include',
});

export default function App() {
  return (
    <ThemeProvider>
      <ApolloProvider client={client}>
        <NotistackProvider>
          <ProgressBarStyle />
          <ChartStyle />
          <ScrollToTop />
          <Router />
        </NotistackProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}
