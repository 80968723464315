import { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { Typography, Tooltip } from '@mui/material';

import RoleBasedGuard from '../../../guards/RoleBasedGuard';

import { ListItemStyle, ListItemTextStyle, ListItemIconStyle, ListItemStyleProps } from './style';
import { NavItemProps } from './types';

// HANDLE SHOW ITEM BY ROLE
const ListItem = forwardRef<HTMLDivElement & HTMLAnchorElement, ListItemStyleProps>(
  (props, ref) => (
    <RoleBasedGuard roles={props.roles}>
      <ListItemStyle {...props} ref={ref}>
        {props.children}
      </ListItemStyle>
    </RoleBasedGuard>
  )
);

export function NavItemRoot({ item, isCollapse, active }: NavItemProps) {
  const { title, path, icon, disabled, caption, roles } = item;

  return (
    <ListItem
      component={RouterLink}
      to={path}
      activeRoot={active}
      disabled={disabled}
      roles={roles}
    >
      {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}

      <ListItemTextStyle
        disableTypography
        primary={title}
        isCollapse={isCollapse}
        secondary={
          <Tooltip title={caption || ''} arrow>
            <Typography
              noWrap
              variant="caption"
              component="div"
              sx={{ textTransform: 'initial', color: 'text.secondary' }}
            >
              {caption}
            </Typography>
          </Tooltip>
        }
      />
    </ListItem>
  );
}
