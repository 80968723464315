import { useState } from 'react';
import { useMutation } from '@apollo/client';

import { Box, Divider, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';

import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';

import { useAuth } from '../../../contexts/AuthContext';
import { LOGOUT_MUTATION } from '../../../store/mutations/Auth';
import createAvatar from '../../../utils/createAvatar';

export default function HeaderPopover() {
  const { partnerActive } = useAuth();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const [logout, { loading }] = useMutation(LOGOUT_MUTATION, {
    onCompleted: () => window.location.reload(),
  });

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar
          src={partnerActive?.partner?.logoUrl || ''}
          alt={partnerActive?.partner?.brandName}
        />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {partnerActive?.partner?.username}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {partnerActive?.partner?.marketingContact}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ m: 1 }}>
          <LoadingButton
            fullWidth
            loading={loading}
            onClick={() => logout()}
            sx={{ justifyContent: 'start', px: 1.5 }}
          >
            Logout
          </LoadingButton>
        </Box>
      </MenuPopover>
    </>
  );
}
