import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Divider, MenuItem, Typography } from '@mui/material';

import { useAuth } from '../../../contexts/AuthContext';

import MenuPopover from '../../../components/MenuPopover';
import MyAvatar from '../../../components/MyAvatar';
import Iconify from '../../../components/Iconify';

import { ICON } from '../../../config';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

type Props = {
  isCollapse: boolean | undefined;
};

export default function NavbarAccount({ isCollapse }: Props) {
  const { partners, partnerActive, partnerSwitch } = useAuth();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleSwitchPartner = (id: number) => {
    if (partnerSwitch) {
      partnerSwitch(id);
      handleClose();
    }
  };

  return (
    <>
      <RootStyle
        sx={{
          cursor: 'pointer',
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
          ...(open && {
            opacity: 0.5,
          }),
        }}
        onClick={handleOpen}
      >
        <MyAvatar
          src={partnerActive?.partner?.logoUrl || ''}
          alt={partnerActive?.partner?.brandName}
        />
        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Typography variant="subtitle2" noWrap>
            {partnerActive?.partner?.brandName}
          </Typography>
        </Box>
        {!isCollapse && (
          <>
            <Box sx={{ flex: 1 }} />
            <Iconify
              icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'}
              sx={{
                flex: 1,
                ml: 0.5,
                width: ICON.NAVBAR_ITEM_HORIZONTAL,
                height: ICON.NAVBAR_ITEM_HORIZONTAL,
              }}
            />
          </>
        )}
      </RootStyle>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        {partners.map((partner, key) => (
          <Box key={key} onClick={() => handleSwitchPartner(key)}>
            <MenuItem sx={{ m: 1 }}>{partner.partner?.brandName}</MenuItem>

            {key + 1 < partners.length && <Divider sx={{ borderStyle: 'dashed' }} />}
          </Box>
        ))}
      </MenuPopover>
    </>
  );
}
