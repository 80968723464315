import { useLocation } from 'react-router-dom';

import { NavItemRoot } from './NavbarItem';

import { NavListProps } from './types';
import { getActive } from './utils';

type NavListRootProps = {
  list: NavListProps;
  isCollapse: boolean;
};

export function NavbarList({ list, isCollapse }: NavListRootProps) {
  const { pathname } = useLocation();

  const active = getActive(list.path, pathname);

  return <NavItemRoot item={list} active={active} isCollapse={isCollapse} />;
}
