import { gql } from '@apollo/client';

import { PARTNER_FIELDS } from '../fragments/Partner';

export const LOGIN_MUTATION = gql`
  ${PARTNER_FIELDS}
  mutation LoginMutation($input: LoginInputModelInput!) {
    login(input: $input) {
      roles
      partner {
        ...PartnerFields
      }
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation LogoutMutation {
    logout
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($input: ForgotpasswordInputModelInput) {
    forgotPassword(input: $input)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetpasswordInputModelInput) {
    resetPassword(input: $input)
  }
`;
