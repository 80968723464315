import { PATH_DASHBOARD } from '../../../routes/paths';

import SvgIconStyle from '../../../components/SvgIconStyle';

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  dashboard: getIcon('ic_dashboard'),
  search: getIcon('ic_search'),
  gamepad: getIcon('ic_gamepad'),
  thresholding: getIcon('ic_thresholding'),
  multiple: getIcon('ic_multiple'),
  games: getIcon('ic_games'),
  provider: getIcon('ic_provider'),
  studio: getIcon('ic_studio'),
  reports: getIcon('ic_reports'),
};

const navbarConfig = [
  // HOME DASHBOARD
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [{ title: 'Dashboard', path: PATH_DASHBOARD.home, icon: ICONS.dashboard }],
  },

  // SEARCH & REPORTS
  // ----------------------------------------------------------------------
  {
    subheader: 'Search & Reports',
    items: [
      { title: 'Search game', path: PATH_DASHBOARD.search.games, icon: ICONS.search },
      { title: 'Reports', path: PATH_DASHBOARD.search.reports, icon: ICONS.reports },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // USERS
      {
        title: 'users',
        path: PATH_DASHBOARD.management.users,
        icon: ICONS.user,
      },
    ],
  },

  // ADMIN
  // ----------------------------------------------------------------------
  {
    subheader: 'admin',
    items: [
      // GAME SERVERS
      {
        title: 'game servers',
        path: PATH_DASHBOARD.admin.servers,
        icon: ICONS.thresholding,
      },
      // PARTNERS
      {
        title: 'partners',
        path: PATH_DASHBOARD.admin.partners,
        icon: ICONS.multiple,
      },
      // GAMES
      {
        title: 'games',
        path: PATH_DASHBOARD.admin.games,
        icon: ICONS.games,
      },
      // PROVIDERS
      {
        title: 'providers',
        path: PATH_DASHBOARD.admin.providers,
        icon: ICONS.provider,
      },
      // STUDIOS
      {
        title: 'studios',
        path: PATH_DASHBOARD.admin.studios,
        icon: ICONS.studio,
      },
    ],
  },
];

export default navbarConfig;
