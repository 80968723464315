import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import LoadingScreen from '../components/LoadingScreen';

import { useAuth } from '../contexts/AuthContext';

import { CurrentUserPermissionsQuery } from '../store/queries/types/CurrentUserPermissionsQuery';
import { CURRENT_USER_PERMISSIONS } from '../store/queries/PartnersAndRoles';
import { PATH_AUTH } from '../routes/paths';

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { partnerAuth } = useAuth();

  const { data, loading } = useQuery<CurrentUserPermissionsQuery>(CURRENT_USER_PERMISSIONS, {
    onCompleted: (data) => {
      if (data.currentUserPermissions && partnerAuth) {
        partnerAuth(data?.currentUserPermissions);
      }
    },
  });

  if (loading) {
    return <LoadingScreen />;
  }

  if (!data?.currentUserPermissions) {
    return <Navigate to={PATH_AUTH.login} />;
  }

  return <>{children}</>;
}
