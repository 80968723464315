import 'simplebar/src/simplebar.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { ManagedAuthContext } from './contexts/AuthContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

import App from './App';

ReactDOM.render(
  <ManagedAuthContext>
    <HelmetProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CollapseDrawerProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </CollapseDrawerProvider>
      </LocalizationProvider>
    </HelmetProvider>
  </ManagedAuthContext>,
  document.getElementById('root')
);
