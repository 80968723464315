import { createContext, useContext, useReducer, useMemo, ReactNode } from 'react';

import { CurrentUserPermissionsQuery_currentUserPermissions } from '../store/queries/types/CurrentUserPermissionsQuery';
import { client } from '../App';

interface IAuthContext {
  partners: CurrentUserPermissionsQuery_currentUserPermissions[] | [];
  partnerActive: CurrentUserPermissionsQuery_currentUserPermissions | null;
  partnerId: number | null;
  partnerAuth?: (value: CurrentUserPermissionsQuery_currentUserPermissions[]) => void;
  partnerSwitch?: (value: number) => void;
}

const TYPES = {
  AUTH: 'CONTEXT_AUTH',
  SWITCH_PARTNER: 'SWITCH_PARTNER',
};

const initialState: IAuthContext = {
  partners: [],
  partnerActive: null,
  partnerId: null,
};

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

AuthContext.displayName = 'AuthContext';

function authReducer(state: IAuthContext, action: any) {
  switch (action.type) {
    case TYPES.AUTH: {
      return {
        ...state,
        partners: action.payload,
        partnerActive: action.payload[0],
        partnerId: action.payload[0].partner.partnerId,
      };
    }
    case TYPES.SWITCH_PARTNER: {
      return {
        ...state,
        partnerActive: state.partners[action.payload],
        partnerId: state.partners[action.payload]?.partner?.partnerId,
      };
    }
  }
}

function AuthProvider(props: any) {
  // @ts-ignore
  const [state, dispatch] = useReducer(authReducer, initialState);

  const partnerAuth = (payload: CurrentUserPermissionsQuery_currentUserPermissions[]) => {
    // @ts-ignore
    dispatch({ type: TYPES.AUTH, payload });
  };
  const partnerSwitch = (payload: number) => {
    // @ts-ignore
    dispatch({ type: TYPES.SWITCH_PARTNER, payload });
    client.reFetchObservableQueries();
  };

  const value: IAuthContext = useMemo(
    () => ({
      ...state,
      partnerAuth,
      partnerSwitch,
    }),
    [state]
  );

  return <AuthContext.Provider value={value} {...props} />;
}

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

export const ManagedAuthContext = ({ children }: { children: ReactNode }) => (
  <AuthProvider>{children}</AuthProvider>
);
