import { gql } from "@apollo/client";

export const PARTNER_FIELDS = gql`
          fragment PartnerFields on Partner{
              partnerId
              brandName
              partnerRoomId
              company
              techContact
              marketingContact
              invoicingContact
              logoUrl
              username
          }`;