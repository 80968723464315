import { ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

// routes
import { PATH_DASHBOARD } from '../routes/paths';
import { useQuery } from '@apollo/client';
import { CurrentUserPermissionsQuery } from '../store/queries/types/CurrentUserPermissionsQuery';
import { CURRENT_USER_PERMISSIONS } from '../store/queries/PartnersAndRoles';
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

export default function GuestGuard() {
  const { data, loading } = useQuery<CurrentUserPermissionsQuery>(CURRENT_USER_PERMISSIONS);

  if (loading) {
    return <LoadingScreen />;
  }

  if (!!data?.currentUserPermissions) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  return <Outlet />;
}
